import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 2560.000000 2560.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,2560.000000) scale(0.100000,-0.100000)" >


<path d="M8760 16100 l0 -470 108 0 c238 0 568 -63 852 -163 221 -77 485 -216
690 -363 243 -173 502 -432 663 -662 269 -382 432 -785 502 -1242 9 -58 20
-197 25 -310 5 -113 16 -241 24 -285 70 -379 308 -694 655 -865 175 -86 297
-113 521 -114 159 0 185 2 280 27 123 32 140 38 241 88 239 116 433 309 548
544 92 186 118 308 131 605 10 229 18 295 55 473 92 446 301 885 585 1228 86
103 269 288 370 373 297 251 655 444 1025 554 220 65 511 112 697 112 l108 0
0 470 0 470 -127 0 c-253 0 -641 -61 -927 -146 -638 -189 -1177 -512 -1643
-985 -462 -469 -783 -1027 -957 -1664 -75 -276 -126 -620 -126 -857 0 -135
-13 -198 -54 -251 -96 -125 -260 -142 -378 -38 -69 60 -81 102 -89 311 -10
227 -21 334 -54 515 -145 785 -508 1472 -1071 2026 -285 281 -549 475 -889
654 -507 268 -1134 435 -1637 435 l-128 0 0 -470z"/>
<path d="M8760 14812 l0 -469 131 -12 c205 -20 356 -61 535 -147 462 -222 789
-663 865 -1169 5 -33 13 -152 19 -265 11 -232 29 -362 72 -535 134 -541 444
-1020 883 -1363 100 -78 90 -71 155 -113 179 -118 346 -201 550 -274 41 -15
86 -31 100 -36 14 -5 81 -22 150 -38 347 -80 642 -90 990 -35 615 99 1198 452
1578 957 301 400 478 883 497 1360 16 390 52 562 171 802 123 251 332 481 574
632 201 125 425 199 679 224 l131 13 0 468 0 468 -47 0 c-61 0 -310 -26 -388
-40 -400 -72 -794 -251 -1115 -507 -112 -89 -311 -288 -389 -388 -34 -44 -68
-87 -74 -95 -46 -58 -157 -240 -206 -338 -102 -204 -175 -415 -220 -639 -29
-147 -40 -244 -51 -468 -14 -299 -46 -441 -153 -665 -141 -296 -375 -539 -671
-696 -180 -95 -336 -144 -541 -169 -149 -19 -220 -19 -366 -1 -275 34 -500
121 -723 279 -281 199 -497 507 -591 842 -30 107 -55 275 -55 374 0 662 -260
1300 -715 1757 -139 139 -171 168 -280 250 -321 241 -710 408 -1090 468 -113
18 -297 36 -362 36 l-43 0 0 -468z"/>
<path d="M16675 13970 c-292 -43 -580 -207 -755 -429 -162 -206 -245 -414
-266 -664 l-7 -87 470 0 471 0 6 44 c14 94 108 187 204 202 l42 6 0 469 0 469
-57 -1 c-32 -1 -80 -5 -108 -9z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
